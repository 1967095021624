import React from 'react';
import Swipeable from 'react-swipeable';

import Uploader from './Uploader';

import deathSeer from "../assets/deathSeer.jpg";
import fantasy from "../assets/fantasy.jpg";
import fireAngel from "../assets/fireAngel.jpg";
import tree from "../assets/tree.jpg";
import plains from "../assets/plains.png";
import island from "../assets/island.jpg";
import ruins from "../assets/ruins.jpg";

const backgrounds = [
    {
        id: "./assets/deathSeer.jpg",
        src: deathSeer
    },
    {
        id: "./assets/fantasy.jpg",
        src: fantasy
    },
    {
        id: "./assets/fireAngel.jpg",
        src: fireAngel
    },
    {
        id: "./assets/tree.jpg",
        src: tree
    },
    {
        id: "./assets/plains.png",
        src: plains
    },
    {
        id: "./assets/island.jpg",
        src: island
    },
    {
        id: "./assets/ruins.jpg",
        src: ruins
    },
]

const BackgroundChooser = ({props, pickBackground, toggleBackgroundChooser, handleBackgroundUpload}) => {

    return (
        <Swipeable onSwipedLeft={toggleBackgroundChooser}>
          <div id="backgrounds-wrapper">
            <Uploader props={props} handleUpload={handleBackgroundUpload} />
            {backgrounds.map(background => {
              return (
                <img
                key={background.id}
                  onClick={e => {
                    pickBackground(e.target.id);
                  }}
                  className="backgrounds-background"
                  id={background.id}
                  src={background.src}
                  alt={`${background.src} background`}
                />
              );
            })}
          </div>
        </Swipeable>
      );
}

export default BackgroundChooser;
import React from "react";
import { Button } from "react-bootstrap";

const Uploader = ({handleUpload}) => {
  return (
    <div>
      <Button
        id="upload-button"
        className="btn btn-success btn-lg"
        onClick={e => {
          e.preventDefault();
          let uploader = document.getElementById("uploader");
          if (uploader) uploader.click();
        }}
        style={{display:'inherit'}}
      >
        <i id="upload-icon" className="fas fa-camera-retro fa-2x" />
        <span className="upload-button-text">Choose Photo</span>
      </Button>
      <input
        id="uploader"
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={input => {
          handleUpload(input.target.files[0]);
        }}
      />
    </div>
  );
};

export default Uploader;
import React, { Component } from "react";
import { Overlay } from "react-bootstrap";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import config from "./firebaseConfig";
import firebaseui from "firebaseui";
import { sendForm } from "emailjs-com";
//components
import Players from "./components/Players";
import SwipeAble from "react-swipeable";
import MessageModal from "./components/MessageModal";
import NameForm from "./components/NameForm";
import Menu from "./components/Menu";
import FullscreenPrompt from "./components/FullscreenPrompt";
import Feedback from "./components/Feedback";
import BackgroundChooser from "./components/BackgroundChooser";

firebase.initializeApp(config);
const ui = new firebaseui.auth.AuthUI(firebase.auth());
const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    //firebase.auth.GoogleAuthProvider.PROVIDER_ID,   --Google no longer allows auth in webviews
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,  --Not set up yet
    //firebase.auth.GithubAuthProvider.PROVIDER_ID,   --Not set up yet
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
    //firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID --No point
  ],
  callbacks: {
    signInSuccessWithAuthResult: function(authResult) {
      let authorizedUser = authResult.user;
      // User successfully signed in.
      console.log(`Logged in as ${authorizedUser.displayName}`);
      return false;
    },
    // signInFailure callback must be provided to handle merge conflicts which
    // occur when an existing credential is linked to an anonymous user.
    signInFailure: function(error) {
      // For merge conflicts, the error.code will be
      // 'firebaseui/anonymous-upgrade-merge-conflict'.
      if (error.code !== "firebaseui/anonymous-upgrade-merge-conflict") {
        return Promise.resolve();
      }
      // The credential the user tried to sign in with.
      // var cred = error.credential;
      // Copy data from anonymous user to permanent user and delete anonymous
      // user.
      // ...
      // Finish sign-in after data is copied.
      //return firebase.auth().signInWithCredential(cred);
    },
    uiShown: () => {
      return null;
    }
  }
};
class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      //anything added to state must be added to {tempstate} and setFinalState()
      players: [
        {
          name: "Player One",
          life: 20,
          wins: 0,
          losses: 0,
          isDead: false,
          isWinner: false,
          id: 1,
          isCritical: false,
          background: require("./assets/deathSeer.jpg"),
          poisonCounters: 0,
          infectCounters: 0
        },

        {
          name: "Player Two",
          life: 20,
          wins: 0,
          losses: 0,
          isDead: false,
          isWinner: false,
          id: 2,
          isCritical: false,
          background: require("./assets/fireAngel.jpg"),
          poisonCounters: 0,
          infectCounters: 0
        }
      ],
      matchOver: false,
      currentPlayerId: null,
      playerDied: false,
      isLoggedIn: false,
      showPoisonDamage: false,
      showInfectDamage: false,
      showNameModal: false,
      showMenuModal: true,
      singlePlayerView: false,
      coinButtonClass: "fas fa-3x fa-adjust",
      diceButtonClass: "fas fa-3x fa-dice-three",
      showMsgModal: false,
      inWalkthrough: false,
      supportsPassive: false,
      showFeedbackForm: false,
      showBackgroundChooser: false,
      showAuth: false
    };
  }
  tempState = {
    //should be carbon copy of state
    players: [
      {
        name: "Player One",
        life: 20,
        wins: 0,
        losses: 0,
        isDead: false,
        isWinner: false,
        id: 1,
        isCritical: false,
        background: require("./assets/deathSeer.jpg"),
        poisonCounters: 0,
        infectCounters: 0
      },

      {
        name: "Player Two",
        life: 20,
        wins: 0,
        losses: 0,
        isDead: false,
        isWinner: false,
        id: 2,
        isCritical: false,
        background: require("./assets/fireAngel.jpg"),
        poisonCounters: 0,
        infectCounters: 0
      }
    ],
    matchOver: false,
    currentPlayerId: null,
    playerDied: false,
    isLoggedIn: false,
    showPoisonDamage: false,
    showInfectDamage: false,
    showNameModal: false,
    showMenuModal: true,
    singlePlayerView: false,
    coinButtonClass: "fas fa-3x fa-adjust",
    diceButtonClass: "fas fa-3x fa-dice-three",
    showMsgModal: false,
    inWalkthrough: false,
    showFeedbackForm: false,
    showBackgroundChooser: false,
    showAuth: false
  };
  consoleColors = {
    warn: "#C24545",
    info: "#3A4585",
    success: "#379B37",
    default: "#C29F45",
    dark: "#424242"
  };
  // Create new object for listeners?
  // Create objects to store methods and information related to each component
  feedback = {
    show: () => {
      this.tempState.showFeedbackForm = true;
      this.setFinalState();
    },
    submitForm: (template, formQuery) => {
      console.log("%cSubmitting form", `color: ${this.consoleColors.default}`);
      sendForm(
        "default_service",
        template,
        formQuery,
        "user_E6Sw8jgkSYgDQSn8TxTYv"
      ).then(
        response => {
          console.log(
            "%cFeedback form submitted",
            `color: ${this.consoleColors.success}`,
            response.status,
            response.text
          );
        },
        function(error) {
          console.log("FAILED...", error);
        }
      );
    }
  };
  menu = {
    //properties only required by the Menu component
    close: () => {
      console.log("%ccloseMenu()", `color: ${this.consoleColors.info}`);
      this.tempState.showMenuModal = false;
      this.setFinalState();
    },
    flipCoin: () => {
      console.log("%cflipCoin", `color: ${this.consoleColors.info}`);
      return new Promise(resolve => {
        let num = Math.random() * 10;
        let result = num & 1;
        this.clearCoinResult();
        setTimeout(() => {
          resolve(result === 0 ? "Heads" : "Tails");
        }, 200);
      });
    },
    getClassName: () => {
      console.log("%cgetClassName", `color: ${this.consoleColors.info}`);
      return new Promise(resolve => {
        let result = this.state.coinButtonClass;
        resolve(result);
      });
    },
    login: () => {
      console.log("%clogin called", `color: ${this.consoleColors.default}`);
      this.tempState.showAuth = true;
      this.setFinalState().then(() => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        ui.start("#firebaseui-auth-container", uiConfig);
      });
    },
    open: () => {
      this.tempState.showMenuModal = true;
      this.setFinalState();
    },
    resetLife: () => {
      this.clearCounters()
        .then(() => {
          this.menu.close();
          this.tempState.resetActive = !this.tempState.resetActive;
          this.tempState.matchOver = false;
          this.tempState.playerDied = false;
          this.tempState.players.forEach(player => {
            player.life = 20;
            player.isDead = false;
            player.poisonCounters = 0;
          });
        })
        .then(() => {
          this.setFinalState().then(() => {
            this.utils.writeState();
          });
        })
        .catch(error => {
          console.error(
            `%cHmm... something's not right. Here's what menu.resetLife().catch() caught -->`,
            "color: #FF6923"
          );
        });
    },
    resetMatch: () => {
      console.log("%cmenu.resetMatch()", `color: ${this.consoleColors.info}`);
      return new Promise(resolve => {
        this.clearCounters()
          .then(() => {
            this.menu.close();
            this.tempState.resetActive = !this.tempState.resetActive;
            this.tempState.matchOver = false;
            this.tempState.playerDied = false;
            this.tempState.players.forEach(player => {
              player.life = 20;
              player.isDead = false;
              player.wins = 0;
              player.losses = 0;
            });
          })
          .then(() => {
            this.setFinalState().then(() => {
              this.utils.writeState();
            });
          })
          .catch(error => {
            console.error(
              `%cHmm... something's not right. Here's what menu.resetMatch().catch() caught --> ${error}`,
              "color: #FF6923"
            );
          });
        resolve();
      });
    },
    roll: () => {
      return new Promise(resolve => {
        let rollButton = document.getElementById("roll-button");
        rollButton.classList.add("active");
        let result = Math.floor(Math.random() * 6) + 1;
        setTimeout(() => {
          rollButton.classList.remove("active");
          resolve(result);
        }, 150);
      });
    },
    setFlipResult: result => {
      switch (result) {
        case "Heads":
          this.tempState.coinButtonClass = "fas fa-3x fa-circle";
          break;
        case "Tails":
          this.tempState.coinButtonClass = "far fa-3x fa-circle";
          break;
        default:
          this.tempState.coinButtonClass = "fas fa-3x fa-adjust";
      }
      this.setFinalState();
    },
    setRollResult: result => {
      switch (result) {
        case 1:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-one";
          break;
        case 2:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-two";
          break;
        case 3:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-three";
          break;
        case 4:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-four";
          break;
        case 5:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-five";
          break;
        case 6:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-six";
          break;
        default:
          this.tempState.diceButtonClass = "fas fa-3x fa-dice-three";
      }
      this.setFinalState();
    },
    toggleInfect: e => {
      this.tempState.showInfectDamage = !this.state.showInfectDamage;
      this.setFinalState();
    },
    togglePoison: () => {
      this.tempState.showPoisonDamage = !this.state.showPoisonDamage;
      this.setFinalState();
    }
  };
  nameForm = {
    handleChange: e => {
      return new Promise((resolve, reject) => {
        let index = this.player.findPlayerById(this.tempState.currentPlayerId);
        this.tempState.players[index].name = e.target.value;
        this.setFinalState();
        resolve();
        reject(() => {
          console.log(
            "%cSomething's not right...",
            `color: ${this.consoleColors.warn}`
          );
        });
      });
    },
    handleSubmit: () => {
      return new Promise(resolve => {
        this.tempState.showNameModal = false;
        this.setFinalState();
        resolve();
      });
    }
  };
  player = {
    //properties only required by the Players component
    addCounter: (playerId, type) => {
      return new Promise(resolve => {
        let index = this.player.findPlayerById(playerId);
        this.tempState.players[index][`${type}Counters`] =
          this.tempState.players[index][`${type}Counters`] + 1;
        this.setFinalState();
        resolve(type);
      });
    },
    animateLifeButton: e => {
      if (!this.state.players.isDead) {
        const target = e.target;
        target.classList.toggle("decrement-active");
        setTimeout(() => {
          target.classList.toggle("decrement-active");
        }, 100);
      }
    },
    decrement: id => {
      console.log("%cdecrement", `color: ${this.consoleColors.info}`);
      let index = this.player.findPlayerById(id);
      if (this.state.matchOver) {
        // match is over
        if (this.state.players[index].isDead) {
          //match is over and this player is dead
          this.utils.showMsg(
            "It's over, bro. You're dead. Start a new battle."
          );
        } else {
          //match over, this player is not dead
          this.utils.showMsg("You already won, start a new battle");
        }
      } else {
        if (this.state.players[index].isdead) {
          //match not over, but this player is dead
          this.utils.showMsg("You're kicking a dead horse there, bud");
        } else {
          //match not over, this player isn't dead
          let newTotal = this.state.players[index].life - 1;
          //if current player dies, call handleDeadth method
          if (newTotal === 0) {
            this.player.handleDeath(id);
          }
          this.tempState.players[index].life = newTotal;
          this.isPlayerCritical(id);
          this.setFinalState();
        }
      }
      return null;
    },
    findPlayerById: id => {
      console.log("%cfindPlayerById", `color: ${this.consoleColors.info}`);
      return this.state.players.map(player => player.id).indexOf(id);
    },
    handleDeath: id => {
      window.navigator.vibrate(1000);
      let index = this.player.findPlayerById(id);
      this.tempState.players[index].isDead = true;
      this.tempState.playerDied = true;
      this.tempState.players[index].losses =
        this.state.players[index].losses + 1;
      this.winnerCheck();
      this.setFinalState();
      if (this.state.isLoggedIn) {
        setTimeout(() => {
          let user = firebase.auth().currentUser;
          if (user === null) {
            return null;
          } else {
            this.utils.writeState();
          }
        }, 500);
      }
      return null;
    },
    handleCounterClick: (e, playerId) => {
      console.group('handleCounterClick')
      if (this.state.players[playerId - 1].isDead) return;
      console.log(e.target.id, playerId);
      let action = e.target.id.split("-")[2].match(/[a-z]+/gi).join('');
      let counterType = e.target.id.split("-")[0];
      console.log(action, counterType)
      switch (counterType) {
        case "poison":
          switch (action) {
            case "increment":
              if (this.tempState.players[playerId - 1].poisonCounters >= 10)
                return;
              this.tempState.players[playerId - 1].poisonCounters += 1;
              break;
            case "decrement":
              if (this.tempState.players[playerId - 1].poisonCounters <= 0)
                return;
              this.tempState.players[playerId - 1].poisonCounters -= 1;
              break;
            default:
              break;
          }
          break;
        case "infect":
          switch (action) {
            case "increment":
              if (this.tempState.players[playerId - 1].infectCounters >= 10)
                return;
              this.tempState.players[playerId - 1].infectCounters += 1;
              break;
            case "decrement":
              if (this.tempState.players[playerId - 1].infectCounters <= 0)
                return;
              this.tempState.players[playerId - 1].infectCounters -= 1;
              break;
            default:
              break;
          }
          break;
        case "energy":
          window.alert("energy counter");
          break;
        default:
          break;
      }
      this.setFinalState().then(() => {
        if (
          this.state.players[playerId - 1].poisonCounters === 10 ||
          this.state.players[playerId - 1].infectCounters === 10
        )
          this.player.killPlayer(playerId);
      });
      console.groupEnd()
    },
    increment: id => {
      let index = this.player.findPlayerById(id);
      if (this.state.matchOver) {
        // match is over
        if (this.state.players[index].isDead) {
          //match is over and this player is dead
          this.utils.showMsg(
            "It's over, bro. You're dead. Start a new battle."
          );
        } else {
          //match over, this player is not dead
          this.utils.showMsg("You already won, start a new battle");
        }
      } else {
        if (this.state.players[index].isdead) {
          //match not over, but this player is dead
        } else {
          this.tempState.players[index].life =
            this.state.players[index].life + 1;
          this.isPlayerCritical(id);
          this.setFinalState();
        }
      }
    },
    killPlayer: id => {
      let index = this.player.findPlayerById(id);
      let playerLife = this.tempState.players[index].life;
      if (playerLife > 0) {
        setTimeout(() => {
          this.player.decrement(id);
          this.player.killPlayer(id);
        }, 50);
      } else {
        return null;
      }
    },
    showNameModal: id => {
      this.tempState.showNameModal = true;
      this.tempState.currentPlayerId = id;
      this.setFinalState();
    }
  };
  utils = {
    //reused in multiple components
    closeModals: async () => {
      console.log("%ccloseModals()", `color: ${this.consoleColors.info}`);
      return new Promise(resolve => {
        this.tempState.showNameModal = false;
        this.tempState.showMenuModal = false;
        this.tempState.showMsgModal = false;
        this.tempState.showFeedbackForm = false;
        this.tempState.showBackgroundChooser = false;
        this.tempState.showAuth = false;
        resolve(this.setFinalState());
      });
    },
    showMsg: (msg, color = "black") => {
      if (this.state.showMsgModal) {
        let msgOutput = document.getElementById("msgOutput");
        msgOutput.classList.add("animated-fade-in");
        setTimeout(() => {
          msgOutput.classList.remove("animated-fade-in");
        }, 350); //if this timeout is changed it must be reflected in the .animated-fade-in animation-duration value in index.css
        this.setMsgText(msg, color);
      } else {
        this.toggleShowMsgModal();
        this.setMsgText(msg, color);
      }
    },
    singlePlayerViewToggle: val => {
      console.log(
        `%csinglePlayerViewToggle(${val})`,
        `color: ${this.consoleColors.info}`
      );
      let orientation = window.screen.orientation.type;
      if (val) {
        console.log(
          `%cVal = ${val} and orientation = ${orientation}`,
          `color: ${this.consoleColors.success}`
        );
      } else {
        console.log(
          `%cVal = ${val} and orientation = ${orientation}`,
          `color: ${this.consoleColors.warn}`
        );
      }
    },
    writeState: () => {
      if (this.state.isLoggedIn) {
        console.log("%cwriteState()", `color: ${this.consoleColors.info}`);
        return new Promise(resolve => {
          const user = firebase.auth().currentUser;
          firebase
            .database()
            .ref(`/users/${user.uid}`)
            .update({ ...this.state }, err => {
              if (err) {
                console.log(
                  `%cAn error occurred: ${err}`,
                  `color: ${this.consoleColors.warn}`
                );
              } else {
                console.log(
                  "%cData saved successfully!",
                  `color: ${this.consoleColors.success}`
                );
                let uid = user.uid;
                resolve(uid);
              }
            });
        });
      } else {
        console.log(
          "%cPlease log in to save data",
          `color: ${this.consoleColors.warn}`
        );
      }
    }
  };
  addKeyboardListener = () => {
    if (window.navigator.maxTouchPoints === 0) {
      window.addEventListener("keyup", this.handleKeyboardEvent, {
        passive: true
      });
    }
  };
  checkPassiveSupport() {
    // Test via a getter in the options object to see if the passive property is accessed
    let supportsPassive = false;
    try {
      const opts = Object.defineProperty({}, "passive", {
        get: function() {
          supportsPassive = true;
          return null;
        }
      });
      window.addEventListener("testPassive", null, opts);
      window.removeEventListener("testPassive", null, opts);
    } catch (e) {
      console.log(
        "%cThis browser does not support passive event listeners.\nConsider upgrading to a newer browser.",
        `color: ${this.consoleColors.warn}`
      );
      supportsPassive = false;
    }
    console.log(
      `%cSupports passive event listeners: ${supportsPassive}`,
      `color: ${this.consoleColors.success}`
    );
  }
  clearCoinResult = () => {
    this.tempState.coinButtonClass = "fas fa-3x fa-adjust";
    this.setFinalState();
  };
  clearCounters = async () => {
    console.log(`%cclearCounters()`, `color: ${this.consoleColors.info}`);
    this.tempState.players.forEach(player => {
      player.poisonCounters = 0;
      player.infectCounters = 0;
    });
  };
  componentDidMount() {
    console.log("%cApp mounted", `color: ${this.consoleColors.success}`);
    this.checkPassiveSupport();
    this.listenForFullscreenChange();
    this.noTouchscreenNotification();
    this.hideLoader();
    this.initFirebaseApp().then(uid => this.getUserData(uid));
  }
  getUserData = uid => {
    //REWRITE LIKE TESTER.JS IN COUNTER42
    console.group("getUserData()");
    return new Promise(resolve => {
      console.log("%cgetUserData()", `color: ${this.consoleColors.info}`);
      firebase
        .database()
        .ref(`/users/${uid}`)
        .once("value")
        .then(snapshot => {
          console.log(
            "%cgetUserData() -> snapshot",
            `color: ${this.consoleColors.success}`
          );
          if (snapshot) {
            let userData = snapshot.val();
            this.tempState = { ...userData };
            this.setFinalState().then(() => {
              this.utils.closeModals();
            });
          }
        })
        .catch(err => {
          console.log(`%c${err}`, `color: ${this.consoleColors.warn}`);
        });
      resolve(console.log(`%cgetUserData Promise resolved`, `color: ${this.consoleColors.success}`));
      console.groupEnd();
    });
  };
  handleKeyboardEvent = e => {
    console.log(
      `%chandleKeyboardEvent(${e.key})`,
      `color: ${this.consoleColors.default}`
    );
    let pressedKey = e.key;
    let shiftPressed = e.shiftKey;
    switch (pressedKey) {
      case "m":
        if (!this.state.showNameModal) {
          this.menu.open();
        }
        break;
      case "Escape":
        this.utils.closeModals();
        break;
      case "ArrowUp":
        shiftPressed ? this.player.increment(2) : this.player.increment(1);
        break;
      case "ArrowDown":
        shiftPressed ? this.player.decrement(2) : this.player.decrement(1);
        break;
      case "ArrowLeft":
        this.player.cycleBackground(1);
        break;
      case "ArrowRight":
        this.player.cycleBackground(2);
        break;
      default:
        break;
    }
  }
  handleBackgroundUpload = async file => {
    //first, get current user
    const user = firebase.auth().currentUser;
    if (user) {
      console.clear();
      let displayName, email, photoUrl, uid, emailVerified;
      if (user.displayName != null) {
        console.group("User Data");
        displayName = user.displayName;
        email = user.email;
        photoUrl = user.photoURL;
        emailVerified = user.emailVerified;
        uid = user.uid;
        console.log(`displayName: ${displayName} `);
        console.log(`email: ${email} `);
        console.log(`photoUrl: ${photoUrl} `);
        console.log(`uid: ${uid} `);
        console.log(`emailVerified: ${emailVerified} `);
        console.groupEnd();
        console.group("Storage");
        const storageRef = firebase.storage().ref();
        const userRef = storageRef.child(uid);
        const uploadsRef = userRef.child("uploads");
        const playerRef = uploadsRef.child(
          `player_${this.state.currentPlayerId}`
        );
        const backgroundImageRef = playerRef.child("background.jpg");
        backgroundImageRef.put(file)
        .then(snapshot => {
          console.log(snapshot);
          const storage = firebase.storage();
          const pathReference = storage.ref(snapshot.metadata.fullPath).location
            .path_;
          storageRef
            .child(pathReference)
            .getDownloadURL()
            .then(url => {
              console.log(`downloadUrl = ${url} `);
              this.tempState.players[
                this.state.currentPlayerId - 1
              ].background = url;
            })
            .then(() => this.setFinalState())
            .then(() => this.utils.closeModals());
        });
        console.groupEnd();
      }
    } else {
      console.log(
        `%cFile uploaded: ${file}`,
        `color: ${this.consoleColors.success}`
      );
      let imgURL = window.URL.createObjectURL(file);
      this.tempState.players[
        this.state.currentPlayerId - 1
      ].background = imgURL;
      this.setFinalState().then(() => {
        this.toggleBackgroundChooser(null);
      });
    }
  };
  hideLoader = () => {
    const loader = document.getElementById("loadingIcon");
    loader.style.display = "none";
  };
  initFirebaseApp = () => {
    return new Promise(resolve => {
      firebase.auth().onAuthStateChanged(user => {
        console.log(
          "%cAuth state change detected",
          `color: ${this.consoleColors.default}`
        );
        if (user) {
          this.tempState.isLoggedIn = true;
          if (user.displayName) {
            //user with ID Provider
            this.tempState.players[0].name = user.displayName.split(" ")[0];
          } else {
            //user with anonymous login
            this.tempState.players[0].name = "Eponymous";
          }
          resolve(user.uid);
        } else {
          this.tempState.isLoggedIn = false;
          this.setFinalState();
        }

        if (
          window.matchMedia("(display-mode: standalone)").matches ||
          window.navigator.standalone === true
        ) {
          console.log("display-mode = standalone");
          this.showPrompt(false);
        }
      });
    });
  };
  isPlayerCritical = id => {
    const index = this.player.findPlayerById(id);
    let currentLifeTotal = this.tempState.players[index].life;
    if (currentLifeTotal !== 0 && currentLifeTotal <= 5) {
      this.tempState.players[index].isCritical = true;
    }
    if (currentLifeTotal > 5) {
      this.tempState.players[index].isCritical = false;
    }
    return null;
  };
  listenForFullscreenChange = () => {
    console.log(
      "%cListening for full screen change",
      `color: ${this.consoleColors.info}`
    );
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );
  };
  logout = () => {
    console.log(`%clogout()`, `color: ${this.consoleColors.info}`);
    this.utils
      .writeState()
      .then(this.menu.resetMatch())
      .then(firebase.auth().signOut())
      .then(()=>{
        this.tempState.players[0].name = 'Player One';
        this.tempState.players[1].name = 'Player Two';
        this.tempState.players[0].background = require("./assets/deathSeer.jpg");
        this.tempState.players[1].background = require("./assets/fireAngel.jpg");
      })
      .then(window.location.reload())
      .catch(err => {
        console.log(`${err}`, `color: ${this.consoleColors.warn}`);
      });
  };
  noTouchscreenNotification = () => {
    if (window.navigator.maxTouchPoints < 1) {
      this.utils.showMsg(
        "I see you're not on a touchscreen. Press [m] to open the menu and [Esc] to close any open windows, including this message.",
        "var(--white)"
      );
      this.addKeyboardListener();
    } else {
      return null;
    }
  };
  onFullScreenChange = () => {
    console.log(
      "%cFullscreen change detected",
      `color: ${this.consoleColors.default}`
    );
    let fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    if (fullscreenElement) {
      this.showPrompt(true);
    } else {
      this.showPrompt(false);
    }
  };
  pickBackground = newBackground => {
    console.log(`%cpickBackground()`, "color: info");
    return new Promise((resolve, reject) => {
      if (newBackground === null || newBackground === undefined) {
        reject(error => console.error(error));
        this.toggleBackgroundChooser();
      } else {
        let index = this.player.findPlayerById(this.tempState.currentPlayerId);
        this.tempState.players[index].background = require(`${newBackground}`);
        this.toggleBackgroundChooser();
      }
      resolve(() => {
        this.setFinalState();
      });
      reject(error => console.error(error));
    }).then(this.utils.writeState());
  };
  setFinalState = () => {
    console.group('setFinalState()')
    return new Promise(resolve => {
      this.setState({
        players: this.tempState.players,
        matchOver: this.tempState.matchOver,
        currentPlayerId: this.tempState.currentPlayerId,
        playerDied: this.tempState.playerDied,
        isLoggedIn: this.tempState.isLoggedIn,
        showPoisonDamage: this.tempState.showPoisonDamage,
        showInfectDamage: this.tempState.showInfectDamage,
        showNameModal: this.tempState.showNameModal,
        showMenuModal: this.tempState.showMenuModal,
        singlePlayerView: this.tempState.singlePlayerView,
        coinButtonClass: this.tempState.coinButtonClass,
        diceButtonClass: this.tempState.diceButtonClass,
        showMsgModal: this.tempState.showMsgModal,
        inWalkthrough: this.tempState.inWalkthrough,
        showFeedbackForm: this.tempState.showFeedbackForm,
        showBackgroundChooser: this.tempState.showBackgroundChooser,
        showAuth: this.tempState.showAuth
      });
      console.groupEnd();
      resolve();
    });
  };
  setMsgText = (text, color) => {
    let msgOutput = document.getElementById("msgOutput");
    msgOutput.innerText = text;
    msgOutput.style.color = color;
    return null;
  };
  showPrompt = show => {
    console.log("%cshowPrompt()", `color: ${this.consoleColors.info}`);
    let prompt = document.getElementById("fullscreen-prompt");
    console.log(
      `%cShow ${prompt.id}: ${!show}`,
      `color: ${this.consoleColors.default}`
    );
    prompt.style.right = show ? "-60px" : "8px";
  };
  toggleBackgroundChooser = id => {
    console.log(`%ctoggleBackgroundChooser(${typeof id})`, "color: info");
    if (typeof id !== "number") {
      console.log(
        `%cClosing background chooser`,
        `color: ${this.consoleColors.info}`
      );
      this.tempState.showBackgroundChooser = false;
    } else {
      this.tempState.showBackgroundChooser = true;
      this.tempState.currentPlayerId = id;
    }
    this.setFinalState();
  };
  toggleShowMsgModal = () => {
    this.tempState.showMsgModal = !this.state.showMsgModal;
    this.setFinalState();
  };
  updatePlayerCard = userData => {
    return new Promise(resolve => {
      this.tempState.players[0].background = userData.background;
      this.tempState.players[0].losses = userData.losses;
      this.tempState.players[0].name = userData.name;
      this.tempState.players[0].wins = userData.wins;
      resolve();
    });
  };
  winnerCheck = () => {
    let champions = this.tempState.players.filter(player => {
      return player.isDead === false;
    });
    if (champions.length === 1) {
      let index = this.player.findPlayerById(champions[0].id);
      this.tempState.players[index].wins = this.state.players[index].wins + 1;
      this.tempState.matchOver = true;
    } else return null;
  };
  render() {
    return (
      <main>
        <FullscreenPrompt />
        <SwipeAble
          onSwipedRight={() => {
            this.menu.open();
          }}
          onSwipedUp={e => {
            this.utils.singlePlayerViewToggle(true);
          }}
          onSwipedDown={e => {
            this.utils.singlePlayerViewToggle(false);
          }}
        >
          <Players
            props={this.state}
            decrement={this.player.decrement}
            increment={this.player.increment}
            handleDeath={this.player.handleDeath}
            animateLifeButton={this.player.animateLifeButton}
            showNameModal={this.player.showNameModal}
            showMsg={this.utils.showMsg}
            addCounter={this.player.addCounter}
            killPlayer={this.player.killPlayer}
            findPlayerById={this.player.findPlayerById}
            handleClick={this.player.handleCounterClick}
            toggleBackgroundChooser={this.toggleBackgroundChooser}
          />
        </SwipeAble>
        <Overlay
          id="background-chooser-overlay"
          show={this.state.showBackgroundChooser}
        >
          <BackgroundChooser
            props={this.state}
            pickBackground={this.pickBackground}
            toggleBackgroundChooser={this.toggleBackgroundChooser}
            handleBackgroundUpload={this.handleBackgroundUpload}
          />
        </Overlay>
        <Overlay show={this.state.showNameModal}>
          <NameForm
            props={this.state}
            handleChange={this.nameForm.handleChange}
            handleSubmit={this.nameForm.handleSubmit}
            writeState={this.utils.writeState}
          />
        </Overlay>
        <Menu
          props={this.state}
          closeModals={this.utils.closeModals}
          toggleInfect={this.menu.toggleInfect}
          togglePoison={this.menu.togglePoison}
          resetLife={this.menu.resetLife}
          resetMatch={this.menu.resetMatch}
          roll={this.menu.roll}
          flipCoin={this.menu.flipCoin}
          showMsg={this.utils.showMsg}
          showFeedback={this.feedback.show}
          setFlipResult={this.menu.setFlipResult}
          getClassName={this.menu.getClassName}
          setRollResult={this.menu.setRollResult}
          showWalkthrough={this.menu.showWalkthrough}
          login={this.menu.login}
          logout={this.logout}
        />
        <SwipeAble onSwipedLeft={this.toggleShowMsgModal}>
        <MessageModal
          props={this.state}
        />
        </SwipeAble>
        <Overlay id="feedback-form" show={this.state.showFeedbackForm}>
          <Feedback
            close={this.utils.closeModals}
            submitForm={this.feedback.submitForm}
          />
        </Overlay>
      </main>
    );
  }
}
export default App;


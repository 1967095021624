import React from "react";
import { ButtonGroup } from "react-bootstrap";
import win from "../assets/win.png";
import loser from "../assets/loser.png";
import PoisonCounter from "./PoisonCounter";
import InfectCounter from "./InfectCounter";
import space from "../assets/space.mp4";

const Players = ({
  props,
  decrement,
  increment,
  showNameModal,
  animateLifeButton,
  toggleBackgroundChooser,
  handleClick
}) => {
  return (
    <div
      id="players-component-wrapper"
      className={
        props.showMenuModal ? "players_wrapper_inactive" : "players_wrapper"
      }
    >
      {props.players.map((player, index) => {
        return (
          <div
            className="playerWrapperOuter"
            style={{
              backgroundImage: `url(${player.background})`
            }}
            id={`player_${index}`}
            key={index}
          >
            <div
              id={`playerWrapperInner_${index}`}
              className="playerWrapperInner bgSwitcher"
              onClick={e => {
                if (e.target.classList.contains("bgSwitcher")) {
                  toggleBackgroundChooser(player.id);
                }
              }}
            >
              <div className="player_header bgSwitcher">
                <div className="player_name bgSwitcher">
                  <span
                    onClick={() => {
                      if (!props.matchOver) showNameModal(player.id);
                    }}
                  >
                    {player.name}
                  </span>
                </div>
              </div>
              <div className="controls">
                <ButtonGroup className="life_total_button_group bgSwitcher">
                  <span
                    id={`life-decrement_${player.id}`}
                    onClick={e => {
                      if (!props.matchOver) {
                        window.navigator.vibrate(50);
                        decrement(player.id);
                        animateLifeButton(e);
                      }
                    }}
                  >
                    <i
                      id={`life-decrement_${player.id}`}
                      className="far fa-minus-square"
                    />
                  </span>
                  <span
                    className={
                      player.life < 6 && !props.matchOver
                        ? "text-center life_total life_total-critical"
                        : "text-center life_total"
                    }
                    style={
                      player.life < 6 && !props.matchOver
                        ? {
                            color: "#C41010"
                          }
                        : player.isDead
                        ? {
                            color: "#C41010",
                            fontFamily: "Nosifer, Germania One",
                            fontSize: "1em"
                          }
                        : {
                            color: "rgba(81, 203, 238, 1)"
                          }
                    }
                  >
                    <div>{player.life}</div>
                  </span>
                  <span
                    id={`life-increment_${player.id}`}
                    onClick={e => {
                      if (!props.matchOver) {
                        window.navigator.vibrate(50);
                        increment(player.id);
                        animateLifeButton(e);
                      }
                    }}
                  >
                    <i className="far fa-plus-square" />
                  </span>
                </ButtonGroup>
              </div>
              <div className="scoreboard bgSwitcher">
                <div className="wins">
                  <img
                    className="scoreboard_image"
                    src={win}
                    alt="trophy icon"
                  />
                  <div className="playerScore">{player.wins}</div>
                </div>
                <div className="losses">
                  <img
                    className="scoreboard_image"
                    src={loser}
                    alt="defeat icon"
                  />
                  <div className="playerScore">{player.losses}</div>
                </div>
              </div>
              <PoisonCounter
                props={props}
                playerId={player.id}
                handleClick={handleClick}
              />
              <InfectCounter
                props={props}
                playerId={player.id}
                handleClick={handleClick}
              />
            </div>
          </div>
        );
      })}
      <div id="background">
        <video autoPlay loop muted controls={false} >
          <source src={space} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Players;

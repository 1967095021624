import React from 'react';
import {Button} from 'react-bootstrap';
const Feedback = ({close, submitForm}) => {
        return (
            <div id="feedback-form-wrapper">
                <form id="feedback-form" template="feedback">{/** template in emailjs.com dashboard */}
                    <div className="input radio-input">
                        <fieldset>
                            <legend id="radio-input-legend">Reason for contact</legend>
                            <div className="inputDiv">
                                <div className="radioDiv">
                                    <input type="radio" id="project" name="reason" value="project" required />
                                    <label>I've got a project for you</label>
                                </div>
                                <div className="radioDiv">
                                    <input type="radio" id="bug" name="reason" value="bug"  />
                                    <label>Report a bug</label>
                                </div>
                                <div className="radioDiv">
                                    <input type="radio" id="feedback" name="reason" value="feedback"  />
                                    <label>Other feedback</label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div id="text-inputs">
                    <div className="input">
                        <label>Name</label>
                        <input id="name-input" type="text" name="user_name" placeholder="Name"></input>
                    </div>
                    <div className="input">
                        <label>Email</label>
                        <input id="email-input" type="email" name="user_email" placeholder="Email"></input>
                    </div>
                    <div className="input">
                        <label>Message</label>
                        <input type="text" id="feedback-input" name="contact" placeholder="Comments"></input>
                    </div>
                    </div>
                    <div>
                    <Button 
                        id="feedback-submit-button" 
                        className="btn btn-success btn-lg" 
                        type="button" 
                        onClick={()=>{
                            console.log('%cSubmitting feedback', 'color: green');
                            let template = document.getElementById('feedback-form').attributes.template.value;
                            submitForm(template,'#feedback-form');
                            close()}}
                    >
                    <i 
                        id="feedback-icon" 
                        className="far fa-paper-plane fa-2x"
                    ></i>
                    <span className="feedback-button-text">Send Feedback</span>
                    </Button>
                    <Button
                        id="back-arrow-button" 
                        className="btn btn-success btn-lg"
                    >
                    <i
                        className="fas fa-arrow-left fa-2x feedback-back-arrow-text" 
                        onClick={(e) => {
                            e.preventDefault();
                            close()
                            }}
                    ></i>
                    </Button>
                    </div>
                </form>
                
            </div>
        );
}
 
export default Feedback;
import React from "react";

const PoisonCounter = ({ props, playerId, handleClick }) => {
  const poisonCounterOuterWrapperStyle = {
    height: "100%",
    width: "100%",
    display: props.showPoisonDamage ? "inline-flex" : "none",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    gridArea: "poison-counter"
  };
  const counterContainerStyle = {
    height: "100%",
    width: "100%",
    display: "inline-grid",
    gridTemplate: "1fr repeat(1fr, 10)",
    gridGap: "1px",
    borderRadius: "var(--border-radius)",
    textAlign: "center",
    backgroundColor: "transparent"
  };
  const poisonCounterStyle = {
    borderRadius: "2px",
    backgroundColor: "var(--poison-color)",
    boxShadow: "0 0 4px var(--poison-color)",
    opacity: ".3"
  };
  const poisonCounterActiveStyle = {
    borderRadius: "2px",
    backgroundColor: "var(--poison-color)",
    boxShadow: "0 0 4px var(--poison-color)",
    opacity: "1"
  };
  return (
    <div
      id={`poison-counter-outer-wrapper_${playerId}`}
      style={poisonCounterOuterWrapperStyle}
    >
      <i
        id={`poison-counter-increment_${playerId}`}
        className="fas fa-angle-up"
        onClick={e => {
          handleClick(e, playerId);
        }}
      />
      <div id={`counter-container_${playerId}`} style={counterContainerStyle}>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 10 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 9 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 8 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 7 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 6 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 5 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 4 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 3 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 2 ? poisonCounterActiveStyle : poisonCounterStyle}/>
        <div
          className={`poison-counter-${playerId}`}
          style={props.players[playerId -1].poisonCounters >= 1 ? poisonCounterActiveStyle : poisonCounterStyle}/>
      </div>
      <i
        id={`poison-counter-decrement_${playerId}`}
        className="fas fa-angle-down"
        onClick={e => {
          handleClick(e, playerId);
        }}
      />{" "}
    </div>
  );
};

export default PoisonCounter;

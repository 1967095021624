import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import Swipeable from "react-swipeable";

const Menu = ({
  props,
  closeModals,
  resetLife,
  resetMatch,
  flipCoin,
  roll,
  setFlipResult,
  setRollResult,
  togglePoison,
  toggleInfect,
  showFeedback,
  login,
  logout,
  showMsg
}) => {
  const authStyle = {
    zIndex: "100",
    display: props.showAuth && !props.isLoggedIn ? "flex" : "none",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: "var(--red)"
  };
  return (
    <Swipeable onSwipedLeft={closeModals}>
      <div id="firebaseui-auth-container" style={authStyle} />
      <div
        id="menuWrapper"
        className={props.showMenuModal ? "menuDiv-active" : "menuDiv-inactive"}
      >
        <header id="menu-header">
          <h1>Counter42</h1>
          <small>
            by 
            <a href="https://portfolio.studio42dev.com" target="Portfolio">
              Studio42Dev
            </a>
          </small>
        </header>
        <div id="menu-controls">
          <ButtonToolbar id="reset">
            <Button 
            id="resetLifeButton" 
            aria-label="reset life button" 
            bsStyle="primary" 
            onClick={resetLife}
            >
              Reset Life
            </Button>
            <Button 
            id="resetWins" 
            aria-label="reset wins" 
            bsStyle="primary" 
            onClick={resetMatch}
            >
              Reset Match
            </Button>
          </ButtonToolbar>
          <ButtonToolbar id="menu_dice-and-damage">
            <Button
              id="flip-button"
              aria-label="flip button"
              onClick={() => {
                window.navigator.vibrate(50);
                flipCoin().then(result => {
                  setFlipResult(result);
                });
              }}
              bsStyle="primary"
            ><span className="sr-only sr-only-focusable">button</span>
              <i id="coin" className={props.coinButtonClass} />
            </Button>
            <Button
              id="infect-toggle-button"
              aria-label="infect toggle button"
              value={2}
              bsStyle={props.showInfectDamage ? "danger" : "primary"}
              checked={props.showInfectDamage}
              onClick={toggleInfect}
            >
              Infect
            </Button>
            <Button
              id="poison-toggle-button"
              aria-label="poison toggle button"
              value={2}
              bsStyle={props.showPoisonDamage ? "danger" : "primary"}
              checked={props.showPoisonDamage}
              onClick={togglePoison}
            >
              Poison
            </Button>
            <Button 
            id="roll-button" 
            aria-label="roll button" 
            bsStyle="primary"
            ><span className="sr-only sr-only-focusable">button</span>
              <i
                aria-label="roll button"
                className={props.diceButtonClass}
                onClick={() => {
                  window.navigator.vibrate(50);
                  roll().then(result => {
                    setRollResult(result);
                  });
                }}
              />
            </Button>
          </ButtonToolbar>
        </div>
        <div id="menu-footer">
          <div id="show-feedback-button" onClick={showFeedback}>
            <span>Feedback</span>
            <i className="far fa-comment-alt fa-lg" />
          </div>
          <div
            id="auth-button"
            onClick={
              props.isLoggedIn
                ? () => {
                    logout();
                  }
                : () => {
                    login();
                  }
            }
          ><span className="sr-only sr-only-focusable">button</span>
            <i
              title={props.isLoggedIn ? "Logout" : "Login"}
              className={
                props.isLoggedIn
                  ? "fas fa-sign-out-alt fa-lg"
                  : "fas fa-sign-in-alt fa-lg"
              }
            />
            <span>{props.isLoggedIn ? "Sign Out" : "Sign In"}</span>
          </div>
          <div id="help" onClick={()=>{
            showMsg("Sign in to remember your name, background, wins and losses. Ever had to leave a game mid-battle? With this, you can pick up exactly where you left off with counters, life totals, names, and scores just where you left them. Swipe left to dismiss the menu or any other window - like this one - and get down with some Magic!", "var(--white)")}}
            >
            <span className="sr-only sr-only-focusable">button</span>
            <i className="far fa-question-circle fa-lg"></i>
            </div>
        </div>
      </div>
    </Swipeable>
  );
};

export default Menu;

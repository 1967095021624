import React from "react";
import { Glyphicon } from "react-bootstrap";

let doc = document.documentElement;
const goFullScreen = () => {
return new Promise((resolve, reject) => {
  if (doc.requestFullscreen) {
    doc.requestFullscreen();
  } else if (doc.mozRequestFullScreen) {
    /* Firefox */
    doc.mozRequestFullScreen();
  } else if (doc.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    doc.webkitRequestFullscreen();
  } else if (doc.msRequestFullscreen) {
    /* IE/Edge */
    doc.msRequestFullscreen();
  }
  resolve(true);
  reject(false);
});
}
const FullscreenPrompt = () => {
  return (
    <Glyphicon
      id="fullscreen-prompt"
      onClick={() => {
        goFullScreen()
      }
      
    }
      glyph="fullscreen"
    />
  );
};
export default FullscreenPrompt;

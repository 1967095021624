import React from 'react';
import { Form, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'

const NameForm = ({ props, handleChange, handleSubmit, writeState }) => {//after finished moving functions to objects in app.js, I can pass in the object related to the component instead of all the individual functions

    return (
        <Form id='name-form' onSubmit={(e) => {
            e.preventDefault();
            handleSubmit().then(writeState());
        }} >
            <FormGroup
                controlId="formBasicText"
            >
                <ControlLabel><h2>What is your name?</h2></ControlLabel>
                <FormControl
                    autoFocus={true}
                    type="text"
                    maxLength='12'
                    placeholder={props.players[props.currentPlayerId - 1].name}
                    onChange={handleChange}
                />
                <FormControl.Feedback />
            </FormGroup>
        </Form>
    );
}

export default NameForm;
import React from "react";

const InfectCounter = ({ props, playerId, handleClick }) => {
  const infectCounterOuterWrapperStyle = {
    height: "100%",
    width: "100%",
    display: props.showInfectDamage ? "inline-flex" : "none",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
    gridArea: "infect-counter"
  };
  const counterContainerStyle = {
    height: "100%",
    width: "100%",
    display: "inline-grid",
    gridTemplate: "1fr repeat(1fr, 10)",
    gridGap: "1px",
    borderRadius: "var(--border-radius)",
    textAlign: "center",
    backgroundColor: "transparent"
  };
  const infectCounterStyle = {
    borderRadius: "2px",
    backgroundColor: "var(--infect-color)",
    boxShadow: "0 0 4px var(--infect-color)",
    opacity: ".3"
  };
  const infectCounterActiveStyle = {
    borderRadius: "2px",
    backgroundColor: "var(--infect-color)",
    boxShadow: "0 0 4px var(--infect-color)",
    opacity: "1"
  };
  return (
    <div
      id={`infect-counter-outer-wrapper_${playerId}`}
      style={infectCounterOuterWrapperStyle}
    >
      <i
        id={`infect-counter-increment_${playerId}`}
        className="fas fa-angle-up"
        onClick={e => {
          handleClick(e, playerId);
        }}
      />
      <div id={`counter-container_${playerId}`} style={counterContainerStyle}>
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 10
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 9
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 8
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 7
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 6
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 5
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 4
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 3
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 2
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
        <div
          className={`infect-counter-${playerId}`}
          style={
            props.players[playerId - 1].infectCounters >= 1
              ? infectCounterActiveStyle
              : infectCounterStyle
          }
        />
      </div>
      <i
        id={`infect-counter-decrement_${playerId}`}
        className="fas fa-angle-down"
        onClick={e => {
          handleClick(e, playerId);
        }}
      />
    </div>
  );
};

export default InfectCounter;

import React from 'react';//use innertext = to output message
//add arguments to function to choose element type

const MessageModal = ({ props, toggleShowMsgModal }) => {

    let msgOutputWrapperStyle = {
        position: "fixed",
        top: "0px",
        left: "0px",
        display: props.showMsgModal ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        zIndex: "100",
        padding: "0"
    }

    let msgOutputStyle = {
        display: "flex",
        minHeight: "50px",
        minWidth: "250px",
        maxWidth: "90vw",
        borderRadius: "8px",
        backgroundColor: 'var(--red)',
        padding: '20px'
    }

    return (<div
        id="msgOutputWrapper"
        style={msgOutputWrapperStyle}
        onClick={toggleShowMsgModal}
    >
        <span
            id="msgOutput"
            style={msgOutputStyle}
        >
        </span>
    </div>)
}

export default MessageModal;